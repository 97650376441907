import React,{useEffect} from 'react';
import CIRCLE_LOOP from "../../images/svg/circle-loop.svg";

import AOS from 'aos';
import 'aos/dist/aos.css';

import { graphql, useStaticQuery } from 'gatsby';

import { getBrText } from "../../helpers/helper.rendering";


const Commitment = () => {
    useEffect(() => {
        AOS.init({ duration : 2000});
    }, [])

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    commitment{
                        title
                        paragraph
                    }
                 }

             }
         }
     }`)

    return(
        <section className="about-compromiso">
            <div className="container-fluid">
                <div className="row items">
                    <div data-aos="fade-left" className=" col-12 d-lg-flex align-items-center px-lg-0 px-3 mb-lg-0">
                        <h1 className="mr-lg-5">{realEstate.sections.about.commitment[0].title}</h1>
                        <p className="float-right">
                            {getBrText(realEstate.sections.about.commitment[0].paragraph).map(element => (
                                <>
                                    {element}
                                    <br className="d-none d-lg-block" />
                                </>
                            ))}
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid half-fluid-about">
                <div className="row">
                    <div data-aos="fade-right" className="col-lg-12 col-11 offset-1 offset-lg-0 d-lg-flex align-items-center px-0 mb-lg-0">
                        <p className="d-none d-lg-block">
                            {getBrText(realEstate.sections.about.commitment[1].paragraph).map(element => (
                                <>
                                    {element}
                                    <br className="d-none d-lg-block" />
                                </>
                            ))}
                        </p>
                        <h1 className="justify-content-left">{realEstate.sections.about.commitment[1].title}</h1>
                        <p className="d-block d-lg-none">
                            {getBrText(realEstate.sections.about.commitment[1].paragraph).map(element => (
                                <>
                                    {element}
                                    <br className="d-none d-lg-block" />
                                </>
                            ))}
                        </p>
                    </div>
                    <CIRCLE_LOOP className="image-rotate" />
                </div>
            </div>
            <div className="row">
                <div data-aos="fade-left" className="col-12 d-flex align-items-lg-center px-lg-0 px-1 p mb-lg-3">
                    <h1 className="mr-lg-5">{realEstate.sections.about.commitment[2].title}</h1>
                    <p className="mb-0">
                        {getBrText(realEstate.sections.about.commitment[2].paragraph).map(element => (
                            <>
                                {element}
                                <br className="d-none d-lg-block" />
                            </>
                        ))}
                    </p>
                </div>
            </div>
        </section>

    )
}

export default Commitment;