import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { getBrText } from "../../helpers/helper.rendering";

const Team = () => {

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections{
                 about{
                    team{
                        hidden
                        title
                        paragraph
                        subtitle
                        items {
                            name
                            type
                            broker_id
                        }
                    }
                    images{
                        team
                    }
                 }

             }
         }
     }`)
    return !realEstate.sections.about.team.hidden ? (
        <section class="the-team" style={{backgroundImage:"url("+ realEstate.sections.about.images.team +")"}}>
            <div className="opacity"></div>
            <div class="container-fluid position-relative">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="section-name">
                            {realEstate.sections.about.team.title}
                        </p>
                    </div>
                    <div class="col-lg-10 mb-lg-5">
                        <h2 class="text-uppercase">
                            {getBrText(realEstate.sections.about.team.paragraph).map((item, index) => (
                                <>
                                    {item}
                                    <br  className='d-none d-lg-block'/>
                                </>
                            ))}
            
                        </h2>
                    </div>
                    <div class="col-lg-10 offset-lg-2 mt-lg-4 my-5 mb-lg-0">
                        <h2 class="my-4 my-lg-0">
                            {realEstate.sections.about.team.subtitle}
                        </h2>
                    </div>
                    <div class="col-lg-10 offset-lg-2 mt-lg-4">
                        <div class="row mt-lg-5">
                            {realEstate.sections.about.team.items.map((item, index) => (
                                <div class="col-lg-4 mb-lg-5">
                                    <div class="card-personal">
                                        <div class="arrow"><i class="icon-arrow-down"></i></div>
                                        <div>
                                            <h4>{item.name}</h4>
                                            <p>{item.type}</p>
                                            {/* <p>{item.broker_id}</p> */}
                                        </div>
            
                                    </div>
                                </div>
                            ))}
                            {/* <div class="col-lg-4">
                                <div class="card-personal">
                                    <div class="arrow"><i class="icon-arrow-down"></i></div>
                                    <div>
                                        <h4>{realEstate.sections.about.team.items[1].name}</h4>
                                        <p>{realEstate.sections.about.team.items[1].type}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card-personal">
                                    <div class="arrow"><i class="icon-arrow-down"></i></div>
                                    <div>
                                        <h4>{realEstate.sections.about.team.items[2].name}</h4>
                                        <p>{realEstate.sections.about.team.items[2].type}</p>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Team;